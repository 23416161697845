import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../components/news-strip";
import arrowSvg from "../images/arrow-circle-right-solid.svg"

class SchoolServicesPage extends Component {

  componentDidMount() {
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Heckmondwike Grammar school services" description="View route information and buy your ticket for the Heckmondwike Grammar school services operated by Tetley's Coaches." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent headblock page-article">
            <h1 className="headblock__heading">Heckmondwike Grammar services</h1>
            <p className="headblock__byline">Buy tickets and view route information for our Heckmondwike Grammar school services</p>
            <p className="headblock__byline" style={{ marginTop: 15 }}><a href="https://passenger.shuttleid.uk">View My Tickets</a></p>
          </div>
          <div className="body gutter page-content-parent page-article">
            <div className="content-box">
              <h2 className="content-box__heading">HGS11</h2>
              <ul>
                <li>Dexter Pub – Heckmondwike Grammar School, Wakefield</li>
                <li>Heckmondwike Grammar School, Wakefield - Dexter Pub</li>
              </ul>
              <div className="btns-group">
                <Link className='btn btn-grow btn-quote' to="/hgs11-service/">
                  <span className='btn-quote__text'>View route</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </Link>
                {/* <a className='btn btn-grow btn-quote btn-ticket' href="https://passenger.shuttleid.uk/client/3108be57-5c93-4d16-84ff-0990d87eba95/7c4193ea-c5d8-4ea2-b851-b792804f2c8d">
                    <span className='btn-quote__text'>Buy ticket</span>
                    <img className='btn-quote__icon' src={arrowSvg} alt="" />
                  </a> */}
              </div>
            </div>
            <div className="content-box">
              <h2 className="content-box__heading">HGS12</h2>
              <ul>
                <li>Savins Mill way - Heckmondwike Grammar School, Wakefield</li>
                <li>Heckmondwike Grammar School, Wakefield - Savins Mill way</li>
              </ul>
              <div className="btns-group">
                <Link className='btn btn-grow btn-quote' to="/hgs12-service/">
                  <span className='btn-quote__text'>View route</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </Link>
                {/* <a className='btn btn-grow btn-quote btn-ticket' href="https://passenger.shuttleid.uk/client/3108be57-5c93-4d16-84ff-0990d87eba95/7c4193ea-c5d8-4ea2-b851-b792804f2c8d">
                    <span className='btn-quote__text'>Buy ticket</span>
                    <img className='btn-quote__icon' src={arrowSvg} alt="" />
                  </a> */}
              </div>
            </div>
            <div className="content-box">
              <h2 className="content-box__heading">HGS13</h2>
              <ul>
                <li>Colton, Leeds – Heckmondwike Grammar School, Wakefield</li>
                <li>Heckmondwike Grammar School, Wakefield - Colton, Leeds</li>
              </ul>
              <div className="btns-group">
                <Link className='btn btn-grow btn-quote' to="/hgs13-service/">
                  <span className='btn-quote__text'>View route</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </Link>
                {/* <a className='btn btn-grow btn-quote btn-ticket' href="https://passenger.shuttleid.uk/client/3108be57-5c93-4d16-84ff-0990d87eba95/7c4193ea-c5d8-4ea2-b851-b792804f2c8d">
                    <span className='btn-quote__text'>Buy ticket</span>
                    <img className='btn-quote__icon' src={arrowSvg} alt="" />
                  </a> */}
              </div>
            </div>
            <div className="content-box">
              <h2 className="content-box__heading">HGS1</h2>
              <ul>
                <li>Wigton Lane, Leeds - Heckmondwike Grammar School, Wakefield</li>
                <li>Heckmondwike Grammar School, Wakefield  - Wigton Lane, Leeds</li>
              </ul>
              <div className="btns-group">
                <Link className='btn btn-grow btn-quote' to="/hgs1-service/">
                  <span className='btn-quote__text'>View route</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </Link>
                <a className='btn btn-grow btn-quote btn-ticket' href="https://passenger.shuttleid.uk/client/3108be57-5c93-4d16-84ff-0990d87eba95/7c4193ea-c5d8-4ea2-b851-b792804f2c8d">
                  <span className='btn-quote__text'>Buy ticket</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </a>
              </div>
            </div>

            <div className="content-box">
              <h2 className="content-box__heading">HGS2</h2>
              <ul>
                <li>Savins Mill Way, Leeds - Heckmondwike Grammar School, Wakefield</li>
                <li>Heckmondwike Grammar School, Wakefield  - Commercial Road, Leeds</li>
              </ul>
              <div className="btns-group">
                <Link className='btn btn-grow btn-quote' to="/hgs2-service/">
                  <span className='btn-quote__text'>View route</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </Link>
                <a className='btn btn-grow btn-quote btn-ticket' href="https://passenger.shuttleid.uk/client/3108be57-5c93-4d16-84ff-0990d87eba95/2353295e-7d60-4c42-8f0d-02ffb44a721c">
                  <span className='btn-quote__text'>Buy ticket</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </a>
              </div>
            </div>



          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default SchoolServicesPage
